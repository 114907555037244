import Container from "juis-components/Container.js";
import {extendComponent, WrapperContainer} from "juis-components/ComponentUtils.js";
import Field from "../../utils/Field.js";
import HTML from "../../utils/HTML.js";
import Icon from "../../bulma_components/Icon.js";
import Component from "juis-components/Component.js";
import Property, {CssProperty} from "juis-components/Property.js";
import {notificationEvents} from "../notification/Notification.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import Row from "./Row.js";
import TextComponent from "../TextComponent.js";
import RestModel from "../../utils/RestModel.js";
import SortDropdown from "./SortDropdown";

export default extendComponent(Container, function () {
    let emptyRow;
    let fields = [];
    let selectedHeader;
    this.textIfEmpty = new Property(() => {
        if (emptyRow) {
            emptyRow.refresh();
        }
    }, "There is nothing to show here :(");
    this.getNode().addWrapper(HTML.DIV, ["table-container", BULMA.BOX]);
    this.tableHead = new WrapperContainer(HTML.TR, [], HTML.THEAD, []);
    this.tableHead.headerRow = new WrapperContainer(HTML.TH, [], HTML.TR, [BULMA.IS_HIDDEN_MOBILE]);
    this.tableHead.mobileSelector = new SortDropdown();
    this.isLoading = new CssProperty("is-loading");
    this.body = new Container(() => {

    }, [], HTML.TBODY);
    let headers = [];

    let rowCount = 0;
    this.appendChild(this.body);
    this.truncate = (showEmpty = true) => {
        rowCount = 0;
        this.body.destroyAllChildren();
        if (showEmpty) {
            emptyRow = this.body.appendChild(this.getEmptyContent());
        } else {
            emptyRow = undefined;
        }
        this.trigger("truncate");
    };

    const isSameField = (a, b) => {
        return a === b ||
            (a.getName && a.getName() === b) ||
            (b.getName && b.getName() === a) ||
            (a.getName && b.getName && a.getName() === b.getName());
    };

    this.markFieldAsSorted = (field, isDesc) => {
        let header = headers
            .filter(header => header.field)
            .find(header => isSameField(header.field, field));
        if (header) {
            markHeaderAsSorted(header, isDesc);
        }
    };

    let markHeaderAsSorted = (header, isDesc) => {
        if (selectedHeader !== header) {
            if (selectedHeader) {
                selectedHeader.isOrderBy = false;
            }
            selectedHeader = header;
            selectedHeader.isOrderBy = true;
        }
        header.isDesc = isDesc;
        if (isDesc) {
            selectedHeader.sortIcon.icon = "angle-down";
        } else {
            selectedHeader.sortIcon.icon = "angle-up";
        }
    };

    const getHeader = (field, settings) => {
        let header;
        const sortable = settings.sortable ?? true;
        let orderBy;
        let label = settings.label;
        if (field instanceof Field || field instanceof RestModel) {
            orderBy = field.getOrderBy();
            label = label || field.getLabel() || field.getName();
        }
        if (orderBy && sortable) {
            header = Container(undefined, ["isSortable"], HTML.TH);
            header.registerDomEvents("click");
            header.isDesc = false;
            const handleSortChange = () => {
                if (selectedHeader === header) {
                    header.isDesc = !header.isDesc;
                }
                header.sortIcon.getNode().togglePlaceholder(false);
                header.sortIcon.icon = "spinner";
                let eventName = "orderAsc";
                if (header.isDesc) {
                    eventName = "orderDesc";
                }
                this.triggerOnce(eventName, field)
                    .catch(error => this.trigger(notificationEvents.NOTIFY, {
                        text: error.message,
                        cssClass: BULMA_CSS_CLASSES.IS_DANGER
                    }))
                    .finally(() => markHeaderAsSorted(header, header.isDesc));
            };
            header.on("click", handleSortChange);
            header.getNode().setAttribute("tabindex", "0");
            header.getNode().setText(label);
            header.sortIcon = new Icon();
            header.isOrderBy = new CssProperty("isCurrentSort")
                .extend((value) => {
                    header.sortIcon.getNode().togglePlaceholder(!value);
                    this.tableHead.mobileSelector.setValue(field.getName());
                });
            header.field = field;
            this.tableHead.mobileSelector.addValue(field.getName(), label);
            this.tableHead.mobileSelector.on("change", () => {
                if (this.tableHead.mobileSelector.getValue() === field.getName()) {
                    handleSortChange();
                }
            });
        } else {
            header = TextComponent(label, HTML.TH);
        }
        headers.push(header);
        return header;
    };

    this.addField = function (field, settings = {}) {
        let index = settings?.index ?? fields.length;
        let tableCell = this.tableHead.headerRow.insertOrderedChild(getHeader(field, settings), index);
        if (field instanceof Field && field.getType() === Field.NUMBER) {
            tableCell.getNode().addCssClass("has-text-right-tablet");
        }
        fields[index] = field;
        return fields.length;
    };

    let rowConstructor = Row;
    this.setRowConstructor = newRowConstructor => {
        rowConstructor = newRowConstructor;
    };
    this.extendRowConstructor = (callback) => {
        rowConstructor = extendComponent(rowConstructor, callback);
        return this;
    };

    const removeEmptyRow = () => {
        if (emptyRow) {
            this.body.removeChild(emptyRow);
            emptyRow = undefined;
        }
    };

    this.addRow = function (entity) {
        removeEmptyRow();
        rowCount = rowCount += 1;
        let row = new rowConstructor((row) => {
            row.setColumns([...fields]);
            row.entity = entity;
        });
        this.body.appendChild(row);
        return row;
    };

    this.removeRow = function (row) {
        row.highlight("delete");
        row.on("animationend", () => {
            this.body.removeChild(row);
        });
    };

    this.addRows = (rows) => {
        return rows.map((row) => this.addRow(row));
    };


    this.getColspan = () => fields.length;

    this.getEmptyContent = () => new Component((row) => {
        row.refresh = () => {
            const html = `
        <TD colspan="${this.getColspan()}" class="empty-table-content">
            <div class="juis icon is-large"><i class="juis fas fa-2x fa-binoculars"></i></div>
            ${this.textIfEmpty}
        </TD>`;
            row.getNode().setInnerHtml(html);
        };
        row.refresh();
    }, [], HTML.TR);

    this.setContent = (rows) => {
        this.truncate(rows.length === 0);
        this.addRows(rows);
    };

    this.addEntityFooter = (entity, fields, rowConstructor = Row) => {
        if (!this.footer) {
            this.footer = new Container(function () {
            }, [], HTML.TFOOT);
        }
        let row = new rowConstructor((row) => {
            row.setColumns([...fields]);
            row.entity = entity;
        });
        this.footer.appendChild(row);
        return row;
    };

}, [BULMA_CSS_CLASSES.TABLE], HTML.TABLE);
