import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import BillItem from "./BillItem.js";
import BillType from "./BillType.js";
import Optional from "../../../lib/JuiS/Optional.js";
import Aggregation from "../../utils/Aggregation.js";
import {
    addressSuggestionInputFactory,
    createSimpleField,
    createTextAreaEditor,
    getDropDownFactory
} from "../../components/fieldEditors/editorFactories.js";
import Contact from "./Contact.js";
import App from "./App.js";
import HTML from "../../utils/HTML.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import {getLocaleDropdownFactory} from "../translations/localeDropdownFactory.js";
import Container from "juis-components/Container.js";
import BULMA_COLORS from "../../bulma_components/bulmaColors.js";
import Payment from "./Payment.js";
import Filter from "../../utils/Filter.js";
import InputWithSuggestions from "../../components/dropdown/InputWithSuggestions.js";
import BulmaUtils from "../../bulma_components/BulmaUtils.js";
import TextComponent from "../../components/TextComponent.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import lexicons from "./lexicons/bill/billLexicon.js";
import LOCALES from "../translations/locales.js";
import localeCellFactory from "../translations/localeCellFactory.js";
import Method from "../../utils/Method.js";
import {downloadBlob, getRestApiUrl} from "../../utils/RestServer.js";
import BillTemplate from "./BillTemplate.js";
import Validation from "../../utils/Validation.js";
import CalculatedPenaltyInterest from "./CalculatedPenaltyInterest";
import {countryCodes, getRegionName} from "../../utils/IntlUtils";

const linguist = new Linguist(lexicons).withAudience(this);
let paymentCellFactory = (value, bill) => {
    let component = new TextComponent(bill.formatMoney(value), HTML.DIV);
    component.getNode().addCssClass(BULMA_CSS_CLASSES.HAS_TOOLTIP_LEFT);
    component.tooltip = BulmaUtils.getTooltipProperty(component);
    let initialTooltipText = "\u231B\uFE0E Loading payment details";
    component.tooltip = initialTooltipText;
    let formatDate = (date) => new Intl.DateTimeFormat("default", {
        year: "numeric",
        month: "long",
        day: "numeric"
    }).format(new Date(date));

    component.registerDomEvents("mouseenter");
    component.on("mouseenter", function () {
        if (component.tooltip !== initialTooltipText) {
            return;
        }
        Payment.getList({filter: Filter.eq(Payment.bill, bill)})
            .then(result => {
                if (!result.data || result.data.length === 0) {
                    component.tooltip = "No payments";
                } else if (result.data.length === 1) {
                    component.tooltip = formatDate(result.data[0].date);
                } else {
                    component.tooltip = result.data
                        .map(payment => `${bill.formatMoney(payment.amount)} on ${formatDate(payment.date)}`)
                        .join("\n");
                }
            });
    });
    return component;
};


export default new Model(function () {
    this.app = new Field(App);
    this.id = new Field().asReadOnly().asNumber();
    this.code = new Field().asString().withLabel("Url Code");
    this.number = new Field().asNumber().withLabel("Number").asReadOnly();
    this.paymentBarcode = new Field().asString().withLabel("Barcode").asReadOnly();
    this.created = new Field()
        .asDate(Linguist.getLanguage())
        .asReadOnly()
        .withLabel("Created");
    this.date = new Field().asDate(Linguist.getLanguage()).withLabel("Date");
    this.year = new Field()
        .asNumber()
        .asReadOnly()
        .withLabel("Year");
    this.monthNumber = new Field()
        .asNumber()
        .asReadOnly()
        .withLabel("Month");
    this.time = new Field().asTimestamp(Linguist.getLanguage()).withLabel("Time");
    this.PAYMENT_TERMS = {
        "IMMEDIATE": "Immediate",
        "NET_7": "Net 7",
        "NET_10": "Net 10",
        "NET_14": "Net 14",
        "NET_30": "Net 30",
        "NET_60": "Net 60",
        "NET_90": "Net 90",
        "EOM": "End of month",
    };
    this.paymentTerm = new Field()
        .withOptions(Object.keys(this.PAYMENT_TERMS))
        .withEditorFactory(getDropDownFactory({
            itemFactory: (paymentTerm) => new TextComponent(this.PAYMENT_TERMS[paymentTerm])
        }))
        .withCellFactory((paymentTerm) => new TextComponent(this.PAYMENT_TERMS[paymentTerm]))
        .withLabel("Payment term");
    this.penaltyInterest = new Field().asNumber(0, 100, 0.1).withLabel("Penalty interest %");
    this.calculatedPenaltyInterest = new Field(CalculatedPenaltyInterest);
    this.referenceNumber = new Field().asString().withLabel("Reference number").asReadOnly();
    this.message = new Field().asString().withLabel("Message").withEditorFactory(createTextAreaEditor).withValidationRule(Validation.maxLength(1000));
    this.dueDate = new Field().asDate(Linguist.getLanguage()).withLabel("Due date");

    const recipientSuggestion = () => {
        return (field, bill) => {
            const input = new InputWithSuggestions();
            input.setOptionsLoader((searchString) => {
                const searchFields = [Contact.name, Contact.street, Contact.city];
                const groupFilter = Filter.inOrEmpty(Contact.contactGroup, bill.type.recipients);
                return Contact.getListForDropdown(searchString, searchFields, bill.app, groupFilter);
            });
            if (bill.recipientName) {
                input.setValue(bill.recipientName);
            } else if (bill.recipient) {
                input.setValue(bill.recipient.name);
            }
            input.setSuggestionToTextMapper(contact => contact.name);
            input.setItemFactory(contact => contact.name);
            input.on("input", () => {
                if (bill.recipient) {
                    bill.recipient = null;
                }
            });
            input.on("suggestionSelected", (contact) => bill.recipient = contact);
            return createSimpleField(field, bill, input);
        };
    };
    this.recipientName = new Field().asString().withLabel("Recipient name").withEditorFactory(recipientSuggestion());
    this.recipient = new Field(Contact)
        .withLabel("Recipient")
        .withCellFactory((recipient, bill) =>
            recipient ? Contact.getDefaultCellFactory()(recipient, bill) : this.recipientName.getCell(bill));
    this.numberAndRecipient = new Field()
        .asVirtual(this.code)
        .withLabel("#")
        .withCellFactory((ignore, bill) => {
            if (bill) {
                return new Container((cell) => {
                    cell.link = this.getDefaultCellFactory()(bill);
                    cell.space = new TextComponent(" · ");
                    cell.recipient = this.recipient.getCell(bill);
                });
            } else {
                return new TextComponent("N/A");
            }
        });
    this.hasDiscount = new Field()
        .asVirtual(undefined,
            (bill) => bill.billItems.some(item => item.discountPercentage > 0))
        .withDefaultValueCallback()
        .withLabel("Has discount");
    this.hasDistinctVatPercentage = new Field()
        .asVirtual(undefined,
            (bill) => bill.billItems.every(item => item.vatPercentage === bill.billItems[0].vatPercentage))
        .withDefaultValueCallback()
        .withLabel("Has distinct VAT percentage");
    this.recipientStreet2 = new Field().asString().withLabel("Recipient street 2");
    this.recipientZipCode = new Field().asString().withLabel("Recipient zip code");
    this.recipientCity = new Field().asString().withLabel("Recipient city");
    this.recipientStreet = new Field().asString().withLabel("Recipient street")
        .withEditorFactory(addressSuggestionInputFactory(this.recipientCity, this.recipientZipCode, linguist));
    this.recipientState = new Field().asString().withLabel("Recipient state");
    this.recipientCountry = new Field().asString().withDefaultValueCallback((bill) => bill.app.country
    ).withOptions(countryCodes).withLabel("Country")
        .withEditorFactory(getDropDownFactory({itemFactory: (country) => new TextComponent(getRegionName(country))}))
        .withCellFactory((country) => new TextComponent(getRegionName(country)));
    this.recipientFinvoiceIdentifier = new Field().asString().withLabel("Finvoice Identifier");
    this.recipientFinvoiceIntermediator = new Field().asString().withLabel("Finvoice Intermediator");
    this.recipientBusinessId = new Field().asString().withLabel("Business ID");
    this.recipientVatNumber = new Field().asString().withLabel("VAT number");
    this.locale = new Field().asString()
        .withLabel("Locale")
        .withOptions(Object.keys(LOCALES))
        .withCellFactory(localeCellFactory)
        .withEditorFactory(getLocaleDropdownFactory());
    this.type = new Field(BillType).withLabel("Type");
    this.template = new Field(BillTemplate)
        .withFilterGetter((bill) => Filter.in(BillTemplate, bill.type.billTemplateOptions))
        .withLabel("Template");
    this.billItems = new Field([BillItem]).withLabel("Items").asCascading();
    this.currencyCode = new Field().asCurrency();
    this.totalPriceVatIncluded = new Field().asMoney(this.currencyCode).withLabel("Total incl. VAT").asReadOnly();
    this.totalPriceVatExcluded = new Field().asMoney(this.currencyCode).withLabel("Total excl. VAT").asReadOnly();
    this.totalVat = new Field().asMoney(this.currencyCode).withLabel("Total VAT").asReadOnly();
    this.reminder = new Field().asBoolean().asVirtual();
    this.hasPaymentMismatch = new Method(function () {
        return this.totalPaid > 0 && this.amountUnpaid !== 0;
    });
    this.amountUnpaid = new Field().asMoney(this.currencyCode).withLabel("Unpaid").asReadOnly();
    this.totalPaid = new Field().asMoney(this.currencyCode).withLabel("Paid").asReadOnly().withCellFactory(paymentCellFactory);

    this.totalPriceVatIncludedSum = Aggregation.sum(this.totalPriceVatIncluded);
    this.totalPriceVatExcludedSum = Aggregation.sum(this.totalPriceVatExcluded);
    this.totalPaidSum = Aggregation.sum(this.totalPaid).withCellFactory((value, bill) => bill.formatMoney(value));
    this.totalAmountUnpaid = Aggregation.sum(this.amountUnpaid);
    this.totalVatSum = Aggregation.sum(this.totalVat);
    this.distinctYears = Aggregation.distinct(this.year);
    this.count = Aggregation.count(this.id, "Count");

    this.status = new Field().asString().withLabel("Status");
    this.nullifies = new Field(this);
    this.nullifiedBy = new Field(this);
    this.relatedTicketIds = new Field([Field.NUMBER]).asWriteOnly();
    this.dropboxLink = new Field().asString().withLabel("Dropbox link");

    this.isLate = function () {
        return this.totalPaid < this.totalPriceVatIncluded && this.dueDate < new Date();
    };

    this.formatMoney = new Method(function (value) {
        const style = "currency";
        const currency = this.currencyCode;
        return new Intl.NumberFormat(this.locale, {style, currency}).format(value);
    });

    this.getBillItem = function (stockProduct) {
        const withSameStockAndProduct = (item) =>
            item.product === stockProduct.product && item.stock === stockProduct.stock;
        return Optional
            .of(this.billItems.find(withSameStockAndProduct))
            .orElseGet(() => {
                const newBillItem = new BillItem();
                newBillItem.app = stockProduct.app;
                newBillItem.product = stockProduct.product;
                newBillItem.stock = stockProduct.stock;
                newBillItem.quantity = 0;
                newBillItem.copyValuesFromProduct(stockProduct.product, this.getFieldsToCopyFromProducts());
                this.billItems.push(newBillItem);
                return newBillItem;
            });
    };

    this.copyValuesFromContact = function (contact) {
        this.recipientName = contact.name;
        this.recipientStreet = contact.street;
        this.recipientStreet2 = contact.street2;
        this.recipientZipCode = contact.zipCode;
        this.recipientCity = contact.city;
        this.recipientBusinessId = contact.businessId;
        this.recipientVatNumber = contact.vatNumber;
        this.recipientFinvoiceIdentifier = contact.finvoiceIdentifier;
        this.recipientFinvoiceIntermediator = contact.finvoiceIntermediator;
        if (contact.locale) {
            this.locale = contact.locale;
        }
        if (contact.paymentTerm) {
            this.paymentTerm = contact.paymentTerm;
        }
    };

    this.nullify = new Method(function () {
        let Bill = this.getModel();
        return this.fetchCustomEndpoint("nullify", this.id)
            .then(json => new Bill(json["data"]))
            .then(nullifyingBill => {
                this.invalidateListFetcherCache();
                this.getModel().trigger(this.EVENTS.AFTER_UPDATE, this);
                Bill.trigger(this.EVENTS.AFTER_INSERT, nullifyingBill);
                return nullifyingBill;
            });
    });

    this.convert = function (newType) {
        return this.fetchCustomEndpoint(`${this.id}/convert-to-type/${newType.id}`)
            .then(json => this.createInstance(json["data"]))
            .then(bill => {
                this.reload();
                return bill;
            });
    };

    this.STATUS = {
        DRAFT: "DRAFT",
        PENDING: "PENDING",
        LATE: "LATE",
        PAID: "PAID",
        NULLIFIED: "NULLIFIED",
    };
    const getStatusColor = (status) => {
        switch (status) {
            case this.STATUS.DRAFT:
                return BULMA_COLORS.GREY_LIGHTER;
            case this.STATUS.PENDING:
                return BULMA_COLORS.YELLOW;
            case this.STATUS.LATE:
                return BULMA_COLORS.RED;
            case this.STATUS.PAID:
                return BULMA_COLORS.GREEN;
            case this.STATUS.NULLIFIED:
                return BULMA_COLORS.CYAN;
            default:
                return BULMA_COLORS.WHITE;
        }
    };

    this.getStatusColor = function (status = this.status) {
        return getStatusColor(status);
    };
    const getStatusCssClass = (status) => {
        switch (status) {
            case this.STATUS.DRAFT:
                return BULMA_CSS_CLASSES.COLORS.HAS_BACKGROUND_GREY_LIGHT;
            case this.STATUS.PENDING:
                return BULMA_CSS_CLASSES.IS_WARNING;
            case this.STATUS.LATE:
                return BULMA_CSS_CLASSES.IS_DANGER;
            case this.STATUS.PAID:
                return BULMA_CSS_CLASSES.IS_SUCCESS;
            case this.STATUS.NULLIFIED:
                return BULMA_CSS_CLASSES.IS_INFO;
            default:
                return BULMA_CSS_CLASSES.IS_PRIMARY;
        }
    };

    this.getStatusCssClass = function (status = this.status) {
        return getStatusCssClass(status);
    };

    this.on("change", (event) => {
        if (event.field === this.recipient && event.value) {
            const contact = event.value;
            const bill = event.entity;
            bill.copyValuesFromContact(contact);
        }
    });

    this.addItems = function (items) {
        items.forEach((item) => this.addItem(item));
    };

    this.addItem = function (billItem) {
        if (!this.billItems.includes(billItem)) {
            billItem.ordinal = this.billItems.length;
            this.billItems.push(billItem);
        }
    };

    this.getUrl = new Method(function () {
        return `/${this.app.code}/bills/${this.code}`;
    });

    this.getText = function () {
        return linguist.t(BillType.name, this.type) + " " + this.number;
    };

    this.createPdf = new Method(function (body) {
        let filename = `${this.app.contactName} - ${BillType.name.getTranslatedValueFor(this.type)} ${this.number}.pdf`;
        let method = "POST";
        let headers = {"Content-Type": "text/html"};
        return fetch(getRestApiUrl(this.getModel().getEndpointUrl(), this.id, "create-pdf"), {method, body, headers})
            .then(response => response.blob())
            .then(blob => downloadBlob(blob, filename));
    });

    this.getPeppolXML = new Method(function (body) {
        let filename = `${this.app.contactName} - ${BillType.name.getTranslatedValueFor(this.type)} ${this.number}.xml`;
        let method = "POST";
        let headers = {"Content-Type": "text/html"};
        const url = getRestApiUrl(this.getModel().getEndpointUrl(), this.id, "send-peppol");
        return fetch(url, {method, body, headers})
            .then(response => {
                const contentType = response.headers.get("Content-Type");
                if (contentType === "text/xml" && response.status >= 200 && response.status < 300) {
                    return response.text().then(text => {
                        const blob = new Blob([text], {type: "text/xml;charset=utf-8;"});
                        downloadBlob(blob, filename);
                    });
                } else {
                    throw new Error("Failed to generate Peppol Invoice");
                }
            });
    });

    this.cachePdf = new Method(function (body) {
        let method = "POST";
        let headers = {"Content-Type": "text/html"};
        return fetch(getRestApiUrl(this.getModel().getEndpointUrl(), this.id, "cache-pdf"), {method, body, headers});
    });

    this.getFieldsToCopyFromProducts = function () {
        const editorFields = this.type.billItemEditorFields;
        const alwaysPresentFields = [
            "price",
            "vatPercentage",
            "priceIncludesVat"
        ];
        const fields = [...new Set([...alwaysPresentFields, ...editorFields])];
        if (!fields.includes("name") && !fields.includes("productNumber") && !fields.includes("description")) {
            fields.push("name");
        }
        return fields;
    };

    setDefaults(this, {
        filterFields: [this.referenceNumber, this.recipientName],
        linkFactory: bill => createLink(bill.getUrl(), bill.getText(), bill.type.icon, bill.getStatusColor()),
        itemFactory: bill => bill.getText()
    });
    linguist.withAudience(this);
    this.linguist = linguist;
}, linguist.t("bill"), "bills");
